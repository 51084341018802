import { gql } from "@apollo/client";

export const CUSTOMER_ORDERS = gql`
  query orders($filters: OrderFiltersInput) {
    customerOrders(filters: $filters) {
      data {
        attributes {
          visitDate
          homeVisit
          number
          status
          fullAddress
        }
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($data: CustomerOrderInput!) {
    cancelOrder(data: $data)
  }
`;

export const CUSTOMER_ORDER_BY_NUMBER = gql`
  query customerOrder($data: CustomerOrderInput!) {
    customerOrder(data: $data) {
      data {
        attributes {
          visitDate
          homeVisit
          number
          cost
          fullAddress
          analyses {
            data {
              id
              attributes {
                title
                description
                price
              }
            }
          }
          analysis_group {
            data {
              id
              attributes {
                title
                description
              }
            }
          }
          status
        }
      }
    }
  }
`;
