import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import { moneyFormatFromNumber } from "../utils/formatter";

// @ts-ignore
const ApplicationItem = ({ intl, analysis }) => (
  <div className="sm:pl-[0.5rem] flex sm:flex-row xs:flex-col justify-between sm:gap-10 xs:gap-3 pt-[0.75rem] justify-between pb-[1rem] border border-b-[#618EE4] border-opacity-25 border-x-white border-t-white border-dotted">
    <p className="sm:w-[55%] xs:w-[100%] font-medium text-[1rem] text-[#001439] ">
      {analysis.title} ({analysis.description})
    </p>
    <div className="flex sm:w-[15%] xs:w-[100%]  sm:justify-end  ">
      <h4 className="font-semibold text-[1.25rem] text-[#001439] ">
        {moneyFormatFromNumber(analysis.price)} ₸
      </h4>
    </div>
  </div>
);

export default injectIntl(ApplicationItem);
