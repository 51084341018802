import * as React from "react";

import Layout from "../components/Layout/Layout";
import HistoryApplicationDetail from "../components/personalCabinet/HistoryApplication/HistoryApplicationDetail";

const historyApplicationDetail = () => {
  return (
    <Layout>
      <HistoryApplicationDetail />
    </Layout>
  );
};

export default historyApplicationDetail;
