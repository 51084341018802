import * as React from "react";

import { injectIntl, Link } from "gatsby-plugin-intl";
import parse from "html-react-parser";
// @ts-ignore
import QR from "../../../images/QR_code.svg";
// @ts-ignore
import Arrow from "../../../images/arrow_left_blue.svg";
import ApplicationItem from "../../ApplicationItem";
import PersonalCabinetSidebar from "../../personalCabinet/PersonalCabinetSidebar";
import HistoryApplication from "./HistoryApplication";
import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CANCEL_ORDER,
  CUSTOMER_ORDER_BY_NUMBER,
} from "../../../query/customerOrders";
import { moneyFormatFromNumber } from "../../../utils/formatter";
import Download from "../../../images/download_white.svg";
import { GET_ORDER_RESULT } from "../../../query/getOrderResult";
import { AppStateContext } from "../../Layout/Layout";

// @ts-ignore
const HistoryApplicationDetail = ({ intl, orderNumber }) => {
  const { profile } = useContext(AppStateContext);

  const [showAnalysisTitle, setShowAnalysisTitle] =
    useState(`HistoryApplication`);

  const [status, setStatus] = useState(null);
  const [result, setResult] = useState({
    done: false,
    base64PdfFile: null,
  });

  const [cancelOrderMutation] = useMutation(CANCEL_ORDER);
  const [getOrderResult] = useLazyQuery(GET_ORDER_RESULT);

  const { data: customerOrder } = useQuery(CUSTOMER_ORDER_BY_NUMBER, {
    variables: {
      data: {
        orderNumber,
      },
    },
  });

  const { myStatus, visitDate, orderType, fullAddress, analyses, cost } =
    (() => {
      if (customerOrder) {
        return {
          myStatus: customerOrder.customerOrder.data.attributes.status,
          visitDate: customerOrder.customerOrder.data.attributes.visitDate,
          orderType: customerOrder.customerOrder.data.attributes.homeVisit
            ? "Home"
            : "Visit",
          fullAddress: customerOrder.customerOrder.data.attributes.homeVisit
            ? customerOrder.customerOrder.data.attributes.fullAddress
            : "ул. Жандосова, 1",
          analyses:
            customerOrder.customerOrder.data.attributes.analyses.data.map(
              (analysisData: any) => ({
                title: analysisData.attributes.title,
                description: analysisData.attributes.description,
                price: analysisData.attributes.price,
              })
            ),
          cost: customerOrder.customerOrder.data.attributes.cost,
        };
      }

      return {
        myStatus: null,
        visitDate: null,
        orderType: null,
        fullAddress: null,
        analyses: null,
        cost: null,
      };
    })();

  useEffect(() => {
    setStatus(myStatus);
  }, [myStatus]);

  const handleDownloadButton = () => {
    getOrderResult({
      variables: {
        data: {
          orderNumber: orderNumber,
          phoneNumber: profile.username,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setResult({
          done: data.getOrderResult.done,
          base64PdfFile: data.getOrderResult.base64PdfFile,
        });
        openBase64NewTab(data.getOrderResult.base64PdfFile);
      },
    });
  };
  const base64toBlob = (base64Data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  };
  const openBase64NewTab = (base64Pdf) => {
    var blob = base64toBlob(base64Pdf);
    if (
      typeof window !== "undefined" &&
      window.navigator &&
      window.navigator.msSaveOrOpenBlob
    ) {
      window.navigator.msSaveOrOpenBlob(blob, `${orderNumber}.pdf`);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };
  const handleCancelOrderButton = () => {
    cancelOrderMutation({
      variables: {
        data: {
          orderNumber,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        console.log({ data });
        setStatus("otmenen");
      },
    });
  };
  console.log({ analyses });

  return customerOrder ? (
    <div className="flex items-center justify-center bg-[#FAFAFA]">
      <div className="flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
        <div className=" mb-10 {/*mt-10*/} flex flex-col justify-start ">
          {/*<div className="w-[100%] flex   gap-16 mb-[2.5rem] ">*/}
          {/*  <h2 className="text-[2rem] text-[#001439] font-semibold ">*/}
          {/*    Иванов Иван Иванович*/}
          {/*  </h2>*/}
          {/*</div>*/}

          <div className="w-[100%] flex  justify-between gap-10 xs:flex-col sm:flex-col lg:flex-row">
            {/*<PersonalCabinetSidebar*/}
            {/*  setShowAnalysisTitle={setShowAnalysisTitle}*/}
            {/*  showAnalysisTitle={showAnalysisTitle}*/}
            {/*/>*/}
            <div className="flex flex-col">
              <Link
                className="flex mb-[1.6rem] cursor-pointer"
                to="/personal/history"
              >
                <img src={Arrow} alt="" />
                <p className="font-medium text-[1.25rem] text-[#618EE4] ml-[0.5rem]">
                  {parse(
                    intl.formatMessage({
                      id: "PersonalCabinet.History.BackToListOfApplication",
                    })
                  )}
                </p>
              </Link>

              <div className="flex flex-col xs:flex-col  border border-opacity-25 border-[#618EE4] bg-white rounded-[1rem] lg:w-[48rem] sm:w-[42rem] xs:w-[18rem] pt-[2rem] lg:pl-[1.5rem] pr-[2rem] xs:pl-[1.2rem] pb-[2.5rem]">
                <div className="flex sm:flex-row xs:flex-col-reverse">
                  <div className="sm:w-[70%] xs:w-[97%]">
                    <h2 className="font-semibold text-[1.75rem]">
                      {parse(
                        intl.formatMessage({
                          id: "ApplicationForm.Application.ApplicationNumber",
                        })
                      )}
                      {orderNumber}
                    </h2>
                    <h4 className="pt-[1rem] font-semibold text-[1.25rem]">
                      {parse(
                        intl.formatMessage({
                          id: `PersonalCabinet.History.OrderType.${orderType}`,
                        })
                      )}
                    </h4>
                    <p className="pt-[1rem] font-medium text-[1rem]">
                      {parse(
                        intl.formatMessage({
                          id: `PersonalCabinet.History.DateOfDeparture.${orderType}`,
                        })
                      )}
                      :
                      <span className="font-semibold ml-[0.3rem]">
                        {parse(
                          intl.formatDate(visitDate, {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          })
                        )}
                        &nbsp;
                        {parse(
                          intl.formatTime(visitDate, {
                            hour: "numeric",
                            minute: "numeric",
                          })
                        )}
                      </span>
                    </p>
                    <p className="pt-[1rem] font-medium text-[1rem]">
                      {parse(
                        intl.formatMessage({
                          id: `PersonalCabinet.History.AddressDeparture.${orderType}`,
                        })
                      )}
                      :
                      <span className="font-semibold ml-[0.3rem]">
                        {fullAddress}
                      </span>
                    </p>
                    {status === "sozdan" ? (
                      <button
                        className=" mt-[1rem] w-[12rem] items-center justify-center border border-black border-1 rounded-[0.56rem] h-[2.75rem] px-[1rem]"
                        onClick={handleCancelOrderButton}
                      >
                        <p className="font-semibold text-[1rem]">
                          {parse(
                            intl.formatMessage({
                              id: "ApplicationForm.Application.CancelButton",
                            })
                          )}
                        </p>
                      </button>
                    ) : null}
                    {status === "zakonchen" && !result.done ? (
                      <button
                        onClick={handleDownloadButton}
                        className=" flex mt-[1rem] w-[19rem] bg-[#618EE4] text-white items-center justify-center rounded-[0.56rem] h-[2.75rem] px-[1rem]"
                      >
                        <img src={Download} alt="" />
                        <p className="font-semibold text-[1rem] ml-[0.5rem]">
                          {parse(
                            intl.formatMessage({
                              id: "PersonalCabinet.History.DownloadResultsButton",
                            })
                          )}
                        </p>
                      </button>
                    ) : null}

                    {status === "zakonchen" && result.done ? (
                      <button
                        onClick={() => openBase64NewTab(result.base64PdfFile)}
                        className=" flex mt-[1rem] w-[19rem] bg-[#618EE4] text-white items-center justify-center rounded-[0.56rem] h-[2.75rem] px-[1rem]"
                      >
                        <img src={Download} alt="" />
                        <p className="font-semibold text-[1rem] ml-[0.5rem]">
                          {parse(
                            intl.formatMessage({
                              id: "PersonalCabinet.History.DownloadResultsButton",
                            })
                          )}
                        </p>
                      </button>
                    ) : null}
                  </div>
                  <div className="w-30% sm:ml-[0rem] xs:-ml-[1rem]">
                    {/*<img src={QR} alt="" className="" />*/}
                  </div>
                </div>
                <div className="mt-[2rem]">
                  {analyses.map((analysis: any, index: number) => (
                    <ApplicationItem
                      analysis={analysis}
                      key={`analysis-${index}`}
                    />
                  ))}

                  {/*House Call Cost*/}
                  {/*<div className="sm:pl-[0.5rem] flex sm:flex-row xs:flex-col justify-between  sm:gap-10 xs:gap-3 pt-[0.75rem] justify-between pb-[1rem] border border-b-[#618EE4] border-opacity-50 border-x-white border-t-white border-dotted">*/}
                  {/*  <p className="sm:w-[55%] xs:w-[100%] font-medium text-[1rem] text-[#001439]">*/}
                  {/*    {intl.formatMessage({*/}
                  {/*      id: "ServiceMenu.HouseCall.TotalCost",*/}
                  {/*    })}*/}
                  {/*  </p>*/}
                  {/*  <div className="flex sm:w-[15%] xs:w-[100%] sm:justify-end ">*/}
                  {/*    <h4 className="font-semibold text-[1.25rem] text-[#001439]">*/}
                  {/*      {moneyFormatFromNumber(1500)} ₸ /!*TODO: need dynamic price for house call*!/*/}
                  {/*    </h4>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>

                <div className="sm:pl-[0.5rem] flex sm:flex-row xs:flex-col justify-between">
                  <h2 className="mt-[1rem] font-semibold text-[1.5rem] text-[#001439]">
                    {intl.formatMessage({ id: "ServiceMenu.HouseCall.Total" })}
                  </h2>
                  <h2 className="mt-[1rem] sm:ml-[0.8rem] font-semibold text-[1.5rem] text-[#001439]">
                    {moneyFormatFromNumber(cost)} ₸
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default injectIntl(HistoryApplicationDetail);
